
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: 'onboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/onboard/onboard.module').then(m => m.OnboardPageModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'navigation',
    loadChildren: () => import('./pages/navigation/navigation.module').then(m => m.NavigationPageModule)
  },
  {
    path: 'my-orders',
    loadChildren: () => import('./pages/my-orders/my-orders.module').then(m => m.MyOrdersPageModule)
  },
  {
    path: 'my-saved',
    loadChildren: () => import('./pages/my-saved/my-saved.module').then(m => m.MySavedPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'onboard',
    loadChildren: () => import('./pages/onboard/onboard.module').then(m => m.OnboardPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'favourite',
    loadChildren: () => import('./pages/favourite/favourite.module').then(m => m.FavouritePageModule)
  },
  {
    path: 'add-items',
    loadChildren: () => import('./pages/add-items/add-items.module').then(m => m.AddItemsPageModule)
  },
  {
    path: 'my-cart',
    loadChildren: () => import('./pages/my-cart/my-cart.module').then(m => m.MyCartPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  {
    path: 'payment-method',
    loadChildren: () => import('./pages/payment-method/payment-method.module').then(m => m.PaymentMethodPageModule)
  },
  {
    path: 'confirm-order',
    loadChildren: () => import('./pages/confirm-order/confirm-order.module').then(m => m.ConfirmOrderPageModule)
  },
  {
    path: 'my-cards',
    loadChildren: () => import('./pages/my-cards/my-cards.module').then(m => m.MyCardsPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'filter',
    loadChildren: () => import('./pages/filter/filter.module').then(m => m.FilterPageModule)
  },
  {
    path: 'manage-profile',
    loadChildren: () => import('./pages/manage-profile/manage-profile.module').then(m => m.ManageProfilePageModule)
  },
  {
    path: 'my-address',
    loadChildren: () => import('./pages/my-address/my-address.module').then(m => m.MyAddressPageModule)
  },
  {
    path: 'reviews',
    loadChildren: () => import('./pages/reviews/reviews.module').then(m => m.ReviewsPageModule)
  },
  {
    path: 'order-tracking',
    loadChildren: () => import('./pages/order-tracking/order-tracking.module').then(m => m.OrderTrackingPageModule)
  },
  {
    path: 'track-order',
    loadChildren: () => import('./pages/track-order/track-order.module').then(m => m.TrackOrderPageModule)
  },
  {
    path: 'empresas',
    loadChildren: () => import('./pages/empresas/empresas.module').then(m => m.EmpresasPageModule)
  },
  //Esto es lo nuevo
  {
    path: 'menu-categorias',
    loadChildren: () => import('./pages/menu-categorias/menu-categorias.module').then(m => m.MenuCategoriasPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorPageModule)
  },
  {
    path: 'finalizar-pedido',
    loadChildren: () => import('./pages/my-orders/finalizar-pedido/finalizar-pedido.module').then(m => m.FinalizarPedidoPageModule)
  },
  {
    path: 'elige-provincia',
    loadChildren: () => import('./pages/provincia/provincia.module').then(m => m.ProvinciaPageModule)
  },
  {
    path: 'modal-qr',
    loadChildren: () => import('./pages/modal-qr/modal-qr.module').then( m => m.ModalQRPageModule)
  },
  {
    path: 'cupones',

    loadChildren: () => import('./pages/cupones/cupones.module').then( m => m.CuponesPageModule)
  },
  {
    path: 'ofertas',
    loadChildren: () => import('./pages/ofertas/ofertas.module').then( m => m.OfertasPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'puntos',
    loadChildren: () => import('./pages/user/puntos-user/puntos-user.module').then( m => m.PuntosUserPageModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('./pages/productos/productos.module').then( m => m.ProductosPageModule)
  },
  {
    path: 'plantilla-productos',
    loadChildren: () => import('./pages/plantilla-productos/plantilla-productos.module').then( m => m.PlantillaProductosPageModule)
  },
  {
    path: 'plantilla-productos-vertical',
 
    loadChildren: () => import('./pages/plantilla-productos-vertical/plantilla-productos-vertical.module').then( m => m.PlantillaProductosVerticalPageModule)
  },
  {
    path: 'producto-completo',
    loadChildren: () => import('./pages/producto-completo/producto-completo.module').then( m => m.ProductoCompletoPageModule)
  },
  {
    path: 'cupon-modal',
    loadChildren: () => import('./pages/cupon-modal/cupon-modal.module').then( m => m.CuponModalPageModule)
  },
  {
    path: 'dar-puntos',
    loadChildren: () => import('./pages/dar-puntos/dar-puntos.module').then( m => m.DarPuntosPageModule)
  },
  {
    path: 'pedir-puntos',
    loadChildren: () => import('./pages/pedir-puntos/pedir-puntos.module').then( m => m.PedirPuntosPageModule)
  },
  {
    path: 'solicitar-puntos',
    loadChildren: () => import('./pages/solicitar-puntos/solicitar-puntos.module').then( m => m.SolicitarPuntosPageModule)
  },
  {
    path: 'confirmar-dar-puntos',
    loadChildren: () => import('./pages/confirmar-dar-puntos/confirmar-dar-puntos.module').then( m => m.ConfirmarDarPuntosPageModule)
  },
  {
    path: 'confirmar-pedir-puntos',
    loadChildren: () => import('./pages/confirmar-pedir-puntos/confirmar-pedir-puntos.module').then( m => m.ConfirmarPedirPuntosPageModule)
  },  {
    path: 'puntos',
    loadChildren: () => import('./pages/puntos/puntos.module').then( m => m.PuntosPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
