import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { BASE_URL_API } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class EmpresasService {
  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    const authUserItem = localStorage.getItem("auth_user");
    const authUser = localStorage.getItem("authUserItem")

    if (authUserItem) {
      const token = JSON.parse(authUserItem).token;
      if (token) {
        headers = headers.set("Authorization", `Bearer ${token}`);
      }
    }else {
      const token = JSON.parse(authUser);
      if (token) {
        headers = headers.set("Authorization", `Bearer ${token}`);
      }
    }
    

    return headers;
  }

  // Método para obtener todas las empresas
  getAllEmpresas(): Observable<any[]> {
    return this.http
      .get<any[]>(`${BASE_URL_API}/empresas`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  // Método para obtener los detalles de una empresa por su ID
  getEmpresaById(id: string): Observable<any> {
    return this.http
      .get<any>(`${BASE_URL_API}/empresas/${id}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  // Otros métodos CRUD según tus necesidades

  // Método para obtener todas las categorias_empresas
  getAllCategoriasEmpresas(): Observable<any[]> {
    return this.http
      .get<any[]>(`${BASE_URL_API}/categorias_empresas`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = "Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.";
    if (error.error instanceof ErrorEvent) {
      console.error("Error del lado del cliente:", error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
      if (error.status === 409) {
        errorMessage = error.error.error;
      }
    }
    return throwError(errorMessage);
  }
}
