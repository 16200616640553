import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URL_API } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  login(pass_hash: string, email: string, id_empresa: string): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/login`, { pass_hash, email, id_empresa })
      .pipe(
        tap(response => {
          console.log('Login exitoso', response);
          this.isLoggedInSubject.next(true); // Actualiza el estado de autenticación
          // Aquí también deberías manejar el almacenamiento de tokens o datos del usuario si es necesario
        }),
        catchError(this.handleError)
      );
  }

  logout(): void {
    // Aquí limpiarías el token de autenticación o los datos del usuario almacenados
    // por ejemplo, en localStorage o cookies

    localStorage.removeItem('auth_token');// Asumiendo que guardas el token aquí
    localStorage.removeItem('auth_user');
    localStorage.removeItem('authUserItem');
    localStorage.clear();
    this.isLoggedInSubject.next(false); // Actualiza el estado de autenticación
    this.router.navigate(['sign-in']); // Redirige al usuario a la página de inicio de sesión
  }

  isAuthenticated(): boolean {
    return this.isLoggedInSubject.value;
  }


  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, body was:`, error.error);
    // Accediendo al mensaje de error específico
    let errorMessage = error.error;
    if (error.status === 401) {
      // Accede directamente al mensaje personalizado de error, si existe.
      errorMessage = error.error || 'Credenciales inválidas. Por favor, verifica tu correo y contraseña.';
    }
    console.log(errorMessage);
    // Otras condiciones para diferentes códigos de estado podrían ir aquí...

    return throwError(errorMessage);
  }
}
