import { Component, Renderer2, OnInit, ViewChild } from '@angular/core';
import { IonAvatar } from '@ionic/angular'; // Asegúrate de importar correctamente IonAvatar
import { ID_PROFESIONAL } from 'src/environments/environment';
import { EmpresasService } from './services/empresas.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  color: number;
  fondo: string;
  c: string;

  @ViewChild(IonAvatar) ionAvatar: IonAvatar; // Obtén una referencia al elemento ion-avatar

  constructor(
    private renderer: Renderer2,
    private empresasPage: EmpresasService
  ) {
    
  }
  

  ngOnInit() {
    this.guardarIdProfesional();
    this.obtenerEmpresa();
  }

  // Metodo para guaradar en local storage la propiedad de ID_PROFESIONAL

  guardarIdProfesional() {
    localStorage.setItem('empresas', ID_PROFESIONAL);
  }

  // Metodo para obtener los datos de la empresa comparando el ID_PROFESIONAL con el ID de la empresa

  obtenerEmpresa() {
    this.empresasPage.getEmpresaById(ID_PROFESIONAL).subscribe((data) => {
      this.color = data.color;
      this.c = this.color.toString();
      localStorage.setItem('color', this.c);
      this.cambiarFondo();
    });
  }


  // Metodo para cambiar el fondo de la pagina segun el color de la empresa
  cambiarFondo() {
    switch (this.color) {
      case 1:
        this.fondo =
          'linear-gradient(135deg, #fd0096 0%, #2a0727 39%, #2a0727 74%, #fd0096 100%)';
        break;
      case 2:
        this.fondo =
          'linear-gradient(135deg, #0093FD 0%, #0D0665 39%, #0D0665 74%, #0093FD 100%)';
        break;
      case 3:
        this.fondo =
          'linear-gradient(135deg, #CCD000 0%, #AC510E 39%, #AC510E 74%, #CCD000 100%)';
        break;
      case 4:
        this.fondo =
        'linear-gradient(135deg, #03B84B 0%, #1D480D 39%, #1D480D 74%, #03B84B 100%)';
          
        break;
      case 5:
        this.fondo =
          'linear-gradient(135deg, #E6E5E5 0%, #262525 39%, #262525 74%, #E6E5E5 100%)';
        break;
      case 6:
        this.fondo =
        'linear-gradient(135deg, #FF3459 0%, #480D0D 39%, #480D0D 74%, #FF3459 100%)';

        break;
      default:
        this.fondo =
          'linear-gradient(135deg, #E6E5E5 0%, #262525 39%, #262525 74%, #E6E5E5 100%)';
          
    }

    // Setea el fondo utilizando Renderer2
    this.renderer.setStyle(document.body, 'background', this.fondo);
  }

}

